import {
  EditButton,
  DeleteButton,
  Stack,
  Typography,
} from "@pankod/refine-mui";
import Image from "components/Image";
import { API_URL } from "constants";
import moment from "moment";

export default {
  title: "events",
  resource: "events",
  canCreate: true,
  columns: [
    {
      field: "image",
      headerName: "Image",
      flex: 1,
      minWidth: 100,
      maxWidth: 100,
      renderCell: function render(params) {
        return (
          <Stack
            direction="column"
            justifyContent="center"
            sx={{ width: "100%", height: "100%" }}
          >
            <Image
              src={API_URL + params.row.image?.url}
              alt={params.row.image?.name}
              sx={{
                borderRadius: 5,
              }}
              height="100%"
              width="100%"
            />
          </Stack>
        );
      },
    },
    { field: "title", headerName: "Title", flex: 1, minWidth: 300 },
    {
      field: "date",
      headerName: "Date",
      flex: 1,
      minWidth: 300,
      renderCell: function render(params) {
        return (
          <Stack
            direction="column"
            justifyContent="center"
            sx={{ width: "100%", height: "100%" }}
          >
            <Typography>{moment(params.row.date).toLocaleString()}</Typography>
          </Stack>
        );
      },
    },
    {
      headerName: "Actions",
      field: "actions",
      minWidth: 50,
      renderCell: function render(params) {
        return (
          <Stack direction="row" spacing={1}>
            <EditButton hideText recordItemId={params.row.id} />
            <DeleteButton hideText recordItemId={params.row.id} />
          </Stack>
        );
      },
    },
  ],
};
