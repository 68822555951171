import { useState, useEffect } from "react";

import { Create, Edit, Grid, Typography, Button } from "@pankod/refine-mui";
import { useForm } from "@pankod/refine-react-hook-form";
import { useNavigation } from "@pankod/refine-core";
import { toast } from "react-toastify";
import { normalizeData } from "utils/utils";
import useLocales from "locales/useLocales";
import { allLangs } from "locales/config";
import axios from "utils/axios";
import { useGetIdentity } from "@pankod/refine-core";

export default function Comp({ isCreate, noLocalize, routeName, Fields }) {
  const {
    refineCore: { formLoading, queryResult },
    saveButtonProps,
    handleSubmit,
    register,
    control,
    formState: { errors },
    getValues,
    setError,
    setValue,
    watch,
  } = useForm();

  const { data: user } = useGetIdentity();
  const { push } = useNavigation();

  const [creatingTranslations, setCreatingTranslations] = useState(false);
  const { currentLang, defaultLang } = useLocales();
  const [localeExists, setLocaleExists] = useState(true);
  const [defaultLocaleData, setDefaultLocaleData] = useState(null);
  const [fieldsSettings, setFieldsSettings] = useState([]);

  useEffect(() => {
    if (noLocalize) {
      return;
    }
    let targetLang = currentLang.value.includes("ru")
      ? currentLang.value
      : currentLang.value.substring(0, 2);

    if (!isCreate && !formLoading && queryResult?.data?.data?.id) {
      let localizations = queryResult.data.data.localizations;

      if (!localizations) {
        // this content type is not localized
        setDefaultLocaleData(queryResult.data.data);
        setLocaleExists(false);
        return;
      }

      if (queryResult.data.data.locale == targetLang) {
        setLocaleExists(queryResult.data.data);
        if (queryResult.data.data.locale == defaultLang.value) {
          setDefaultLocaleData(queryResult.data.data);
        } else {
          let defLang = localizations.find((l) => l.locale == defaultLang);
          if (defLang)
            axios
              .get(`/api/${routeName}/${defLang.id}?populate=deep,2`)
              .then((res) => {
                setDefaultLocaleData(normalizeData(res.data));
              })
              .catch((err) => {
                console.log(err);
                toast.error("Something went wrong");
              });
        }
      } else {
        let languageExists = (localizations || []).find(
          (l) => l.locale == targetLang
        );
        if (languageExists) {
          window.open(`/${routeName}/edit/${languageExists.id}`, "_self");
        }
        setLocaleExists(languageExists);
      }
    }
  }, [currentLang.value, formLoading]);

  const handleCreate = async (e, values) => {
    e.preventDefault();
    // console.log("FIELDS", fieldsSettings);
    setCreatingTranslations(true);
    try {
      // trasnslations data
      let langFrom = currentLang.value;
      let defLang = defaultLang.value ? defaultLang.value : defaultLang;
      let langTo = defLang;
      let route = routeName.slice(0, -1);
      let fields =
        fieldsSettings.filter((f) => f.localized).map((f) => f.name) || [];
      // console.log("LOCALIZED FIELDS", fields);
      // process entry creation
      let currentLangEntry = null;
      if (routeName == "posts")
        values = {
          ...values,
          owner: user.id,
        };
      // create default language
      let { data } = await axios.post(`/api/${routeName}`, {
        data: values,
      });
      if (noLocalize) {
        push(`/${routeName}`, "_self");
        return;
      }
      // check if it is the same as default language
      if (currentLang.value == defaultLang) {
        currentLangEntry = data.data;
      } else {
        // create translation for the default language
        if (fields.length > 0)
          await axios.post(`/api/translate`, {
            id: data.data.id,
            route,
            fields,
            langFrom,
            langTo,
          });
      }
      // create all the localizations
      for (let lang of allLangs) {
        console.log("Creating Localisation for ", lang.value, defLang);
        if (lang.value != defLang) {
          try {
            let { data: newLang } = await axios.post(
              `/api/${routeName}/${data.data.id}/localizations`,
              {
                locale: lang.value.includes("ru")
                  ? lang.value
                  : lang.value.substring(0, 2),
                ...values,
              }
            );
            if (lang.value == currentLang.value) {
              // console.log("Current Lang Is Created", lang.value, newLang);
              currentLangEntry = newLang?.data ? newLang.data : newLang;
            }
            // create translation
            if (fields.length > 0)
              await axios.post(`/api/translate`, {
                id: newLang.id,
                route,
                fields,
                langFrom,
                langTo: lang.value,
              });
          } catch (error) {
            console.log("Localization Error", error);
            toast.error("Something went wrong while creating localization");
          }
        }
      }
      // console.log("currentLangEntry", currentLangEntry);
      // open the selected language entry
      push(`/${routeName}/edit/${currentLangEntry.id}`, "_self");
    } catch (error) {
      console.log("Create Error", error);
      toast.error("Something went wrong while creating entry");
    }
    setCreatingTranslations(false);
  };

  // useEffect(() => {
  //   console.log(fieldsSettings);
  // }, [fieldsSettings]);

  if (isCreate) {
    return (
      <Create
        isLoading={formLoading}
        saveButtonProps={{
          onClick: (e) => handleCreate(e, getValues()),
          loading: creatingTranslations,
        }}
      >
        <Fields
          register={register}
          control={control}
          errors={errors}
          getValues={getValues}
          setError={setError}
          setValue={setValue}
          watch={watch}
          setFieldsSettings={setFieldsSettings}
        />
      </Create>
    );
  } else {
    if (!localeExists) {
      return (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{ height: "100%" }}
        >
          <Grid item>
            <Typography variant="h6" gutterBottom component="div">
              This language does not exist. Please create it first.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => {
                axios
                  .post(
                    `/api/${routeName}/${queryResult?.data?.data?.id}/localizations`,
                    {
                      locale: currentLang.value.includes("ru")
                        ? currentLang.value
                        : currentLang.value.substring(0, 2),
                    }
                  )
                  .then((res) => {
                    console.log(res.data);
                    window.open(`/${routeName}/edit/${res.data.id}`, "_self");
                  })
                  .catch((err) => {
                    console.log(err);
                    toast.error("Something went wrong");
                  });
              }}
            >
              Create
            </Button>
          </Grid>
        </Grid>
      );
    }

    return (
      <Edit isLoading={formLoading} saveButtonProps={saveButtonProps}>
        <Fields
          defaultLanguageRecord={defaultLocaleData}
          register={register}
          control={control}
          errors={errors}
          getValues={getValues}
          setError={setError}
          setValue={setValue}
          watch={watch}
          setFieldsSettings={setFieldsSettings}
        />
      </Edit>
    );
  }
}
